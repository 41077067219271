.MuiFormLabel-root.Mui-focused,
.MuiPickersDay-current,
.MuiCheckbox-colorSecondary.Mui-checked,
.MuiButton-textPrimary {
  color: #5cbdaf !important;
}

.MuiFab-primary {
  color: #fff;
  background-color: #5cbdaf !important;
}

.MuiPickersClockPointer-thumb,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #5cbdaf !important;
}

.MuiInput-underline:after {
  border-bottom-color: #5cbdaf !important;
}

.MuiPickersDay-daySelected,
.MuiPickersToolbar-toolbar,
.MuiPickersClockPointer-pointer,
.MuiPickersClock-pin,
.MuiPickersClockPointer-noPoint {
  background-color: #5cbdaf !important;
}

.MuiPickersDay-daySelected {
  color: #fff !important;
}

.MuiRadio-colorSecondary.Mui-checked,
.MuiTypography-colorPrimary,
.MuiSwitch-colorSecondary.Mui-checked {
  color: #5cbdaf !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #64c5b6 !important;
}

.MuiCircularProgress-colorPrimary {
  color: #5cbdaf !important;
}

.progress-container {
  display: flex;
  justify-content: center;
  min-height: 20vh;
  align-items: center;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-row {
  cursor: pointer;
}

.disable-cursor-pointer.MuiDataGrid-root .MuiDataGrid-row {
  cursor: auto;
}

.disable-cursor-pointer.MuiDataGrid-root .MuiDataGrid-row .linkIconWrapper_1sqthdm {
  display: none !important;
}

.participant-attachments.MuiDataGrid-root .MuiDataGrid-row {
  cursor: auto;
}

.participant-attachments.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer,
.participant-attachments.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing {
  display: flex;
  align-items: center;
}

.participantsTable .MuiDataGrid-root .MuiDataGrid-row {
  cursor: auto;
}

.visitor-checkbox.MuiIconButton-edgeEnd {
  margin-right: 0 !important;
  padding: 0 !important;
}

.MuiDataGrid-root.notesTable .MuiDataGrid-row {
  width: fit-content;
  display: flex;
  height: auto;
  min-height: auto !important;
  max-height: 100% !important;
}

.MuiDataGrid-root.notesTable .MuiDataGrid-cell {
  display: block;
  overflow: inherit !important;
  white-space: unset !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-overflow: inherit !important;
  max-height: 100% !important;
  min-height: auto !important;
  line-height: 1.5 !important;
  padding: 16px !important;
}

.MuiDataGrid-root.notesTable .MuiDataGrid-viewport {
  overflow: initial !important;
  max-height: 100% !important;
}

.MuiPaper-elevation8 {
  box-shadow: 1px 1px 5px 0px #9b9b9b40 !important;
}

.MuiSlider-thumbColorSecondary:hover {
  box-shadow: 0px 0px 0px 8px rgb(92 189 176 / 11%) !important;
}

.MuiSlider-colorSecondary {
  color: #5cbdb0 !important;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDateRangePickerWrapper {
  font-family: sans-serif;
  display: flex;
}

.rdrStaticRangeLabel {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 25px;
  font-size: 14px;
}
.rdrDayToday .rdrDayNumber span:after {
  background-color: #3f51b5;
}
.datePicker {
  justify-content: space-around;
}
.rdrStaticRange {
  border: none;
}
.rdrCalendarWrapper,
.rdrDefinedRangesWrapper {
  flex: 1;
  border: none;
}

.flex-items-center {
  display: flex;
  align-items: center;
}

.nd-hide-mui-input .MuiInputBase-input {
  visibility: hidden;
}

.nd-hide-mui-input .MuiInputLabel-shrink {
  transform: translate(0, 24px) scale(1);
  transform-origin: unset;
}

.MuiButton-root[hidden] {
  display: none;
}

/* // other overrides */
@media (max-width: 600px) {
  .rdrDateRangePickerWrapper {
    display: block !important;
  }
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
  .rdrCalendarWrapper {
    display: flex !important;
  }
  .rdrMonthsVertical {
    flex-direction: initial !important
    ;
  }
}


.MuiDataGrid-row.Mui-even {
  background-color: #fbfbfb;
}

.participant-merge-desc strong {
  white-space: nowrap;
}

.MuiButton-root.Mui-disabled.action-button {
  color: #fff;
}

.add-flag-icon-space textarea {
  padding-right: 20px;
}

#activity-form .MuiInputLabel-root {
  font-size: 0.9rem !important;
}

.rdw-editor-wrapper h1 {
  line-height: unset;
  font-size: 24px;
  margin-bottom: 5px;
  margin: unset;
}

.rdw-editor-wrapper h2 {
  line-height: unset;
  font-size: 18px;
  margin-bottom: 5px;
  margin: unset;
}

.rdw-editor-wrapper .public-DraftStyleDefault-block {
  margin: 5px;
}

.region-op-divider {
  position: relative;
  width: 100%
}

.region-op-divider::after {
  content: '';
  width: calc(100% + 30px);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  position: absolute;
  left: -15px;
  z-index: 1;
  margin-top: 30px;
}


.global-etiquette-disabled {
  user-select: none;
  kcursor: not-allowed !important;
}

.global-etiquette-disabled:hover {
  cursor: not-allowed !important;
  background-color: unset;
}
