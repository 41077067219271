.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;
}

.cookie-consent__text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #000;
  margin-right: 1rem;
}

.cookie-consent__close {
  background: rgb(92, 189, 175) !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(92, 189, 175) !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  box-shadow: rgba(0, 0, 0, 0.17) 2px 2px 8px 1px !important;
}

.cookie-consent__close:hover {
  color: rgb(92, 189, 175) !important;
  background: rgb(255, 255, 255) !important;
  box-shadow: none !important;
}
